import { combineReducers } from "redux"
import phinExtractReducer, { PhinExtractState } from "./extract/reducer"
import phinMissingInformationExtractReducer, {
	PhinMissingInformationExtractState
} from "./missingInformation/reducer"

export interface PhinExtractsState {
	phinExtract: PhinExtractState
	phinMissingInformation: PhinMissingInformationExtractState
}

const phinExtractsReducer = combineReducers({
	phinExtract: phinExtractReducer,
	phinMissingInformation: phinMissingInformationExtractReducer
})

export default phinExtractsReducer
