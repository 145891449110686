import { combineReducers } from "redux"
import phinExtractsReducer from "./phin"
import consultantEmailsReducer, {
	ConsultantEmailsState
} from "./consultantemails/reducer"
import spireReducer, { SpireExtractState } from "./spire/reducer"
import chelwestExtractReducer from "./chelwest/reducer"
import ggcExtractReducer from "./ggc/reducer"
import scanExtractReducer from "./scan/reducer"
import amlReducer from "./aml/reducer"
import nhsSussexReducer from "./nhssussex/reducer"
import mehExtractReducer from "./meh/reducer"
import nhsWalesReducer, { NhsWalesExtractState } from "./nhswales/reducer"
import ExtractState from "../../models/ExtractState"
import { PhinExtractsState } from "./phin/reducer"
import tlcExtractReducer, { TlcExtractState } from "./tlc/reducer"
import cadoganExtractReducer, { CadoganExtractState } from "./cadogan/reducer"
import circleExtractReducer, { CircleExtractState } from "./circle/reducer"
import hcaExtractReducer, { HcaExtractState } from "./hca/reducer"
import uriExtractReducer from "./uri/reducer"
import lillyBreastCancerReducer, {
	LillyBreastCancerExtractState
} from "./lillybreastcancer/reducer"
import lillyIbdReducer, { LillyIbdExtractState } from "./lillyibd/reducer"

export interface ExtractsState {
	aml: ExtractState
	cadogan: CadoganExtractState
	chelwest: ExtractState
	circle: CircleExtractState
	consultantEmails: ConsultantEmailsState
	ggc: ExtractState
	hca: HcaExtractState
	lillyBreastCancer: LillyBreastCancerExtractState
	lillyIbd: LillyIbdExtractState
	meh: ExtractState
	nhsWales: NhsWalesExtractState
	nhsEngland: ExtractState
	phin: PhinExtractsState
	scan: ExtractState
	spire: SpireExtractState
	tlc: TlcExtractState
	nhsSussex: ExtractState
	uri: {
		surveyAnswer: ExtractState
		adhoc: ExtractState
	}
}

const extractsReducer = combineReducers({
	aml: amlReducer,
	cadogan: cadoganExtractReducer,
	chelwest: chelwestExtractReducer,
	circle: circleExtractReducer,
	consultantEmails: consultantEmailsReducer,
	ggc: ggcExtractReducer,
	hca: hcaExtractReducer,
	lillyBreastCancer: lillyBreastCancerReducer,
	lillyIbd: lillyIbdReducer,
	meh: mehExtractReducer,
	nhsWales: nhsWalesReducer,
	phin: phinExtractsReducer,
	scan: scanExtractReducer,
	spire: spireReducer,
	tlc: tlcExtractReducer,
	nhsSussex: nhsSussexReducer,
	uri: uriExtractReducer
})

export default extractsReducer
