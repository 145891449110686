export function flattenArray<T>(array: Array<Array<T>>): Array<T> {
	const emptyArray: T[] = []
	const flattened = emptyArray.concat.apply([], array)
	return flattened
}

export function distinct<T>(array: T[], stringify: (x: T) => string): T[] {
	const set: Record<string, T> = {}
	array.forEach((x) => (set[stringify(x)] = x))
	return Object.values(set)
}
