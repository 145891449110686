import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import { SearchFilters } from "../../../models/Search"
import { SortingRule } from "react-table"

export type PatientRow = {
	patient: {
		patientId: number
		patientUuid: string
		userId: string
		firstName: string
		lastName: string
		email: string
		dateOfBirth: string
		isLockedOut?: boolean
		nhsNumber?: string
		chiNumber?: string
		genericIrelandPatientIdentifier?: string
		state: string
		deleteDate?: string
	}
	patientCondition: {
		patientConditionId: number
		patientConditionUuid: string
		problemAreaName: string
		treatmentCentreNames: string[]
		spellNumber?: string
		consentToShare: boolean
		patientConditionCreatedDate: string
	}
}
export interface SearchState {
	filters?: SearchFilters
	toggleColumns: Object
	searchQuery: string
	loading: boolean
	page: number
	pageSize: number
	data: PatientRow[]
	totalResults: number
	sorted: SortingRule<PatientRow>[]
}

const initialSearchPatients: SearchState = {
	filters: undefined,
	toggleColumns: {
		"PC UUID": false,
		"Patient UUID": false,
		"CHI Number": false,
		"NHS Number": false,
		"Generic Ireland ID": false,
		"Spell Number": false,
		"Date Of Birth": false,
		"PC Created Date": false
	},
	searchQuery: "",
	loading: false,
	page: 0,
	pageSize: 10,
	data: [],
	totalResults: 0,
	sorted: [{ id: "email", desc: false }]
}

function searchPatientsReducer(
	state: SearchState = initialSearchPatients,
	action: any
): SearchState {
	switch (action.type) {
		case ActionTypes.PATIENTS_SEARCH_FILTER_REQUEST:
			return {
				...state,
				filters: undefined,
				page: 0,
				loading: true
			}
		case ActionTypes.PATIENTS_SEARCH_FILTER_RECEIVE:
			return {
				...state,
				filters: SearchFilters.CreateFromPayload(action.payload),
				loading: false
			}
		case ActionTypes.PATIENTS_SEARCH_RESET:
			return {
				...initialSearchPatients
			}
		case ActionTypes.PATIENTS_SEARCH_FILTER_UPDATE_SELECTED:
			return {
				...state,
				page: 0,
				filters:
					state.filters &&
					state.filters.updateSelectedInFilters(
						action.filterPipeline,
						action.checked
					)
			}
		case ActionTypes.PATIENTS_SEARCH_FILTER_QUERY_UPDATE:
			return {
				...state,
				searchQuery: action.searchQuery
			}
		case ActionTypes.PATIENTS_SEARCH_TOGGLE_COLUMNS_UPDATE_SELECTED:
			return {
				...state,
				toggleColumns: {
					...state.toggleColumns,
					[action.column]: action.selected
				}
			}
		case ActionTypes.PATIENTS_SEARCH_PAGE_INDEX_UPDATE:
			return {
				...state,
				page: action.page
			}
		case ActionTypes.PATIENTS_SEARCH_PAGE_Size_AND_INDEX_UPDATE:
			return {
				...state,
				page: action.page,
				pageSize: action.pageSize
			}
		case ActionTypes.PATIENTS_SEARCH_SORTED_UPDATE:
			return {
				...state,
				sorted: action.sorted
			}
		case ActionTypes.PATIENTS_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			}
		case ActionTypes.PATIENTS_SEARCH_RECEIVE:
			return {
				...state,
				loading: false,
				data: action.payload.patients,
				totalResults: action.payload.total
			}
		case ActionTypes.PATIENTS_SEARCH_FAILURE:
			toast.error("Unable to get patients")
			return {
				...state,
				loading: false
			}
	}
	return state
}

export default searchPatientsReducer
