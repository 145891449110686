
export const allTenants = [
		{
			subDomain: "aimovig",
			typeName: "Aimovig",
			codeName: "TENANT.AIMOVIG",
			internalName: "Aimovig",
			disabledZones: [
				],
			loginsDisabledAfter: "2023-02-21T00:00:00.0000000",
			importer: () => import("./Aimovig/Aimovig")
		},
		{
			subDomain: "aml",
			typeName: "Aml",
			codeName: "TENANT.AML",
			internalName: "AML",
			disabledZones: [
				],
			loginsDisabledAfter: "2024-04-10T00:00:00.0000000",
			importer: () => import("./Aml/Aml")
		},
		{
			subDomain: "benenden",
			typeName: "Benenden",
			codeName: "TENANT.BENENDEN",
			internalName: "Benenden",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Benenden/Benenden")
		},
		{
			subDomain: "cadogan",
			typeName: "Cadogan",
			codeName: "TENANT.CADOGAN",
			internalName: "Cadogan",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Cadogan/Cadogan")
		},
		{
			subDomain: "chelwest",
			typeName: "ChelWest",
			codeName: "TENANT.CHELWEST",
			internalName: "ChelWest",
			disabledZones: [
				],
			loginsDisabledAfter: "2023-10-16T00:00:00.0000000",
			importer: () => import("./ChelWest/ChelWest")
		},
		{
			subDomain: "circle",
			typeName: "Circle",
			codeName: "TENANT.CIRCLE",
			internalName: "Circle",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Circle/Circle")
		},
		{
			subDomain: "ggc",
			typeName: "Ggc",
			codeName: "TENANT.GGC",
			internalName: "GGC",
			disabledZones: [
				],
			loginsDisabledAfter: "0001-01-01T00:00:00.0000000",
			importer: () => import("./Ggc/Ggc")
		},
		{
			subDomain: "hcauk",
			typeName: "Hca",
			codeName: "TENANT.HCA",
			internalName: "HCA",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Hca/Hca")
		},
		{
			subDomain: "hje",
			typeName: "Hje",
			codeName: "TENANT.HJE",
			internalName: "HJE",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Hje/Hje")
		},
		{
			subDomain: "horderhealthcare",
			typeName: "Horder",
			codeName: "TENANT.HORDER",
			internalName: "Horder Healthcare",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Horder/Horder")
		},
		{
			subDomain: "pbc",
			typeName: "IpsenPbc",
			codeName: "TENANT.IPSENPBC",
			internalName: "IpsenPbc",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./IpsenPbc/IpsenPbc")
		},
		{
			subDomain: "lillyepromsbreastcancer",
			typeName: "LillyBreastCancer",
			codeName: "TENANT.LILLYBREASTCANCER",
			internalName: "Lilly Breast Cancer",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./LillyBreastCancer/LillyBreastCancer")
		},
		{
			subDomain: "lillyepromsibd",
			typeName: "LillyIbd",
			codeName: "TENANT.LILLYIBD",
			internalName: "Lilly IBD",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./LillyIbd/LillyIbd")
		},
		{
			subDomain: "meh",
			typeName: "Meh",
			codeName: "TENANT.MEH",
			internalName: "Moorfields (MEH)",
			disabledZones: [
				],
			loginsDisabledAfter: "2024-02-01T00:00:00.0000000",
			importer: () => import("./Meh/Meh")
		},
		{
			subDomain: "mhg",
			typeName: "Mhg",
			codeName: "TENANT.MHG",
			internalName: "MHG",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Mhg/Mhg")
		},
		{
			subDomain: "mph",
			typeName: "Mph",
			codeName: "TENANT.MPH",
			internalName: "MPH",
			disabledZones: [
				],
			loginsDisabledAfter: "2025-03-01T00:00:00.0000000",
			importer: () => import("./Mph/Mph")
		},
		{
			subDomain: "mpn10",
			typeName: "Mpn10",
			codeName: "TENANT.MPN10",
			internalName: "MPN10",
			disabledZones: [
				],
			loginsDisabledAfter: "2023-08-02T00:00:00.0000000",
			importer: () => import("./Mpn10/Mpn10")
		},
		{
			subDomain: "nhsaa",
			typeName: "Nhsaa",
			codeName: "TENANT.NHSAA",
			internalName: "NHSAA",
			disabledZones: [
				],
			loginsDisabledAfter: "2024-05-17T00:00:00.0000000",
			importer: () => import("./Nhsaa/Nhsaa")
		},
		{
			subDomain: "nhssussex",
			typeName: "NhsSussex",
			codeName: "TENANT.NHSSUSSEX",
			internalName: "NHS Sussex",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./NhsSussex/NhsSussex")
		},
		{
			subDomain: "nhsproms",
			typeName: "NhsWales",
			codeName: "TENANT.NHSWALES",
			internalName: "NHS Wales",
			disabledZones: [
				],
			loginsDisabledAfter: "2025-01-08T00:00:00.0000000",
			importer: () => import("./NhsWales/NhsWales")
		},
		{
			subDomain: "trackingmyms",
			typeName: "NovartisMS",
			codeName: "TENANT.NOVARTISMS",
			internalName: "Novartis MS",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./NovartisMS/NovartisMS")
		},
		{
			subDomain: "rcht",
			typeName: "Rcht",
			codeName: "TENANT.RCHT",
			internalName: "RCHT",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Rcht/Rcht")
		},
		{
			subDomain: "royalsurrey",
			typeName: "RoyalSurrey",
			codeName: "TENANT.ROYALSURREY",
			internalName: "Royal Surrey",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./RoyalSurrey/RoyalSurrey")
		},
		{
			subDomain: "nhsscotland",
			typeName: "Scan",
			codeName: "TENANT.SCAN",
			internalName: "SCAN (NHS Scotland)",
			disabledZones: [
				],
			loginsDisabledAfter: "2024-03-27T00:00:00.0000000",
			importer: () => import("./Scan/Scan")
		},
		{
			subDomain: "spencerprivatehospitals",
			typeName: "SpencerPrivateHospitals",
			codeName: "TENANT.SPENCER",
			internalName: "Spencer",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./SpencerPrivateHospitals/SpencerPrivateHospitals")
		},
		{
			subDomain: "spire",
			typeName: "Spire",
			codeName: "TENANT.SPIRE",
			internalName: "Spire",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Spire/Spire")
		},
		{
			subDomain: "stjosephs",
			typeName: "StJosephs",
			codeName: "TENANT.STJOSEPHS",
			internalName: "St Joseph's",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./StJosephs/StJosephs")
		},
		{
			subDomain: "tlc",
			typeName: "Tlc",
			codeName: "TENANT.TLC",
			internalName: "TLC",
			disabledZones: [
				],
			loginsDisabledAfter: "9999-12-31T23:59:59.9999999",
			importer: () => import("./Tlc/Tlc")
		},
		{
			subDomain: "trigger",
			typeName: "Trigger",
			codeName: "TENANT.TRIGGER",
			internalName: "Trigger",
			disabledZones: [
				],
			loginsDisabledAfter: "2021-07-01T00:00:00.0000000",
			importer: () => import("./Trigger/Trigger")
		},
] as const