import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import ExtractState from "../../../../models/ExtractState"

export interface PhinMissingInformationExtractState extends ExtractState {
	uploadInProgress: boolean
	uploadErrors: string
}
const initialPhinMissingInformationExtract: PhinMissingInformationExtractState =
	{
		downloadInProgress: false,
		sendToSftpInProgress: false,
		uploadInProgress: false,
		uploadErrors: ""
	}

function phinMissingInformationExtractReducer(
	state: PhinMissingInformationExtractState = initialPhinMissingInformationExtract,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_PHIN_MISSING_INFORMATION_SEND_TO_SFTP_REQUEST: {
			return {
				...state,
				sendToSftpInProgress: true
			}
		}
		case ActionTypes.EXTRACTS_PHIN_MISSING_INFORMATION_SEND_TO_SFTP_RECEIVE:
			toast.success("Extract started. Check Slack for details")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.EXTRACTS_PHIN_MISSING_INFORMATION_SEND_TO_SFTP_FAILURE:
			toast.error("Unable to start Phin missing information extract")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.EXTRACTS_PHIN_MISSING_INFORMATION_UPLOAD_REQUEST: {
			return {
				...state,
				uploadInProgress: true,
				uploadErrors: ""
			}
		}
		case ActionTypes.EXTRACTS_PHIN_MISSING_INFORMATION_UPLOAD_RECEIVE: {
			toast.success("Successfully uploaded missing information")
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: ""
			}
		}
		case ActionTypes.EXTRACTS_PHIN_MISSING_INFORMATION_UPLOAD_FAILURE: {
			toast.error(
				"Unable to upload and process the Missing Information extract"
			)
			const message =
				(action.payload &&
					action.payload.response &&
					action.payload.response.Message) ||
				"The upload failed but the response is empty. This could be because the server was not contacted at all. Check the Chrome Developer Tools console for the error message."
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: message
			}
		}
	}
	return state
}

export default phinMissingInformationExtractReducer
