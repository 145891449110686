import { useSelector } from "react-redux"
import { State } from "../../state/reducer"
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icons from "../Icons"
import { getFormattedDate } from "../../services/DateHelpers"

const TimeTravelBadge = () => {
	const { overriddenDate } = useSelector((state: State) => state.environment)

	if (!overriddenDate) {
		return null
	}

	return (
		<span className="h2 mt-1">
			<OverlayTrigger
				placement={"right"}
				overlay={
					<Tooltip id="time-travel-tooltip">
						Time travelling to {getFormattedDate(overriddenDate)}
					</Tooltip>
				}
			>
				<Badge variant="info">
					<FontAwesomeIcon icon={Icons.History} />
				</Badge>
			</OverlayTrigger>
		</span>
	)
}

export default TimeTravelBadge
