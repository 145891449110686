import { DateTime } from "luxon"

const isMinDate = (date?: string) => date === "0001-01-01T00:00:00"
export function getFormattedDate(date?: string | DateTime): string {
	if (date instanceof DateTime) {
		return date.toFormat("yyyy-MM-dd")
	}

	if (!date || isMinDate(date)) {
		return "-"
	}
	return DateTime.fromISO(date).toFormat("yyyy-MM-dd")
}

export function getFormattedDateTime(date?: string): string {
	if (!date || isMinDate(date)) {
		return "-"
	}
	return DateTime.fromISO(date).toFormat("yyyy-MM-dd HH:mm")
}
