import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import { PatientUser } from "../../../models/User"
import { PatientCondition } from "../../../models/PatientCondition"
import SalutationKey from "mco-keys/src/t4/SalutationKey"

export type PhinTestData = {
	condition: string
	bodyPart: string
	testCaseName: string
	patientEmail: string
}

export type ConsultantNameData = {
	id: number
	salutationKey: SalutationKey
	firstName: string
	lastName: string
}

export interface UserState {
	patient: PatientUser
	patientMedicalAreas: Array<PatientCondition>
	loadingUser: boolean
	phinTestData: PhinTestData[]
	fixPatientInProgress: boolean
	newConditionTreatmentCentres: string[]
	newConditionConsultants: ConsultantNameData[]
}

const initialUser: UserState = {
	patient: {} as PatientUser,
	patientMedicalAreas: [],
	loadingUser: false,
	phinTestData: [],
	fixPatientInProgress: false,
	newConditionTreatmentCentres: [],
	newConditionConsultants: []
}

function userReducer(state: UserState = initialUser, action: any) {
	switch (action.type) {
		case ActionTypes.PATIENT_DETAILS_REQUEST:
			return {
				...state,
				loadingUser: true
			}
		case ActionTypes.PATIENT_DETAILS_RECEIVE:
			return {
				...state,
				...action.payload,
				loadingUser: false
			}
		case ActionTypes.PATIENT_DETAILS_FAILURE:
			toast.error("Unable to load patient details")
			return {
				...state,
				patient: {},
				patientMedicalAreas: [],
				loadingUser: false
			}
		case ActionTypes.PATIENT_LOCK_RECEIVE:
			toast.success("Successfully locked user")
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_LOCK_FAILURE:
			toast.error("Unable to lock user")
			return state
		case ActionTypes.PATIENT_UNLOCK_RECEIVE:
			toast.success("Successfully unlocked user")
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_UNLOCK_FAILURE:
			toast.error("Unable to unlock user")
			return state
		case ActionTypes.PATIENT_DELETE_RECEIVE:
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_DELETE_FAILURE:
			toast.error("Unable to delete user")
			return state
		case ActionTypes.PATIENT_RESET_REQUEST:
			return {
				...state,
				loadingUser: true
			}
		case ActionTypes.PATIENT_RESET_RECEIVE:
			toast.success("Successfully reset user")
			return {
				...state,
				loadingUser: false,
				...action.payload
			}
		case ActionTypes.PATIENT_RESET_FAILURE:
			toast.error("Unable to reset user")
			return {
				...state,
				loadingUser: false
			}
		case ActionTypes.PATIENT_RESTORE_RECEIVE:
			toast.success("Successfully restored user")
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_RESTORE_FAILURE:
			toast.error("Unable to restore user")
			return state
		case ActionTypes.PATIENT_PMA_DELETE_EXTRACTS_REQUEST:
			toast.success("Successfully deleted extracts for PHIN resubmission")
			break
		case ActionTypes.PATIENT_PMA_DELETE_EXTRACTS_FAILURE:
			toast.error("Unable to delete extracts for PHIN resubmission")
			break
		case ActionTypes.PATIENT_PMA_DELETE_EXTRACTS_RECEIVE:
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_PMA_DELETE_REQUEST:
			toast.success("Successfully deleted PMA")
			break
		case ActionTypes.PATIENT_PMA_DELETE_FAILURE:
			toast.error("Unable to delete PMA")
			break
		case ActionTypes.PATIENT_CONDITION_VALIDATE_RECEIVE:
			toast.success("Validated patient condition successfully")
			break
		case ActionTypes.PATIENT_CONDITION_VALIDATE_FAILURE:
			toast.error("Unable to validate patient condition")
			break
		case ActionTypes.PATIENT_USER_UPDATE_RECEIVE:
			toast.success("User updated successfully")
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_USER_UPDATE_FAILURE:
			toast.error("Unable to update user")
			break
		case ActionTypes.PATIENT_TREATMENT_CREATE_RECEIVE:
			toast.success("Treatment created successfully")
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_TREATMENT_CREATE_FAILURE:
			toast.error("Unable to create treatment")
			break
		case ActionTypes.PATIENT_TREATMENT_UPDATE_RECEIVE:
			toast.success("Treatment updated successfully")
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_TREATMENT_UPDATE_FAILURE:
			toast.error("Unable to update treatment")
			break
		case ActionTypes.PATIENT_TREATMENT_DELETE_RECEIVE:
			toast.success("Treatment deleted successfully")
			return {
				...state,
				...action.payload
			}
		case ActionTypes.PATIENT_TREATMENT_DELETE_FAILURE:
			toast.error("Unable to delete treatment")
			break
		case ActionTypes.PATIENT_FAKE_DATA_CREATE_SINGLE_RECEIVE:
			toast.success("Patient(s) Created")
			break
		case ActionTypes.PATIENT_FAKE_DATA_CREATE_SINGLE_FAILURE:
			toast.error("Error Creating Patient(s)")
			break
		case ActionTypes.PATIENT_USER_UPDATE_NOTE_RECEIVE:
			return {
				...state,
				patient: {
					...state.patient,
					adminNotes: action.meta
				}
			}
		case ActionTypes.PATIENT_CONDITION_ADD_RECEIVE:
			return {
				...state,
				patientMedicalAreas: action.payload
			}
		case ActionTypes.PATIENT_CONDITION_ADD_GET_TREATMENT_CENTRES_RECEIVE:
			return {
				...state,
				newConditionTreatmentCentres: action.payload
			}
		case ActionTypes.PATIENT_CONDITION_ADD_GET_CONSULTANTS_RECEIVE:
			return {
				...state,
				newConditionConsultants: action.payload
			}
		case ActionTypes.PATIENT_FAKE_DATA_CREATE_PHIN_REQUEST:
			return {
				...state,
				phinTestData: []
			}
		case ActionTypes.PATIENT_FAKE_DATA_CREATE_PHIN_RECEIVE:
			return {
				...state,
				phinTestData: action.payload
			}
		case ActionTypes.PATIENT_FIX_BROKEN_PATIENT_REQUEST:
			return {
				...state,
				fixPatientInProgress: true
			}
		case ActionTypes.PATIENT_FIX_BROKEN_PATIENT_RECEIVE:
			toast.success("Patient fixed")
			return {
				...state,
				fixPatientInProgress: false
			}
		case ActionTypes.PATIENT_FIX_BROKEN_PATIENT_FAILURE:
			toast.error("Patient could not be fixed; check Airbrake for errors")
			return {
				...state,
				fixPatientInProgress: false
			}
		case ActionTypes.PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_REQUEST:
			return {
				...state,
				fixPatientInProgress: true
			}
		case ActionTypes.PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_RECEIVE:
			toast.success("Patient fixed, new condition invite sent")
			return {
				...state,
				fixPatientInProgress: false
			}
		case ActionTypes.PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_FAILURE:
			toast.error("Patient could not be fixed; check Airbrake for errors")
			return {
				...state,
				fixPatientInProgress: false
			}
		case ActionTypes.PATIENT_FIX_NEW_BROKEN_PATIENT_REQUEST:
			return {
				...state,
				fixPatientInProgress: true
			}
		case ActionTypes.PATIENT_FIX_NEW_BROKEN_PATIENT_RECEIVE:
			toast.success("Patient fixed, new patient invite sent")
			return {
				...state,
				fixPatientInProgress: false
			}
		case ActionTypes.PATIENT_FIX_NEW_BROKEN_PATIENT_FAILURE:
			toast.error("Patient could not be fixed; check Airbrake for errors")
			return {
				...state,
				fixPatientInProgress: false
			}
	}
	return state
}

export default userReducer
