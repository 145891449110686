import { TenantOption } from "mco-tenant-configurations"
import { ListGroupItem } from "react-bootstrap"
import Icons from "../Icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useIsTenantShutdown, useSetTenantSubDomain } from "./TenantHooks"
import { getFormattedDate } from "../../services/DateHelpers"

export function TenantButton({ tenantOption }: { tenantOption: TenantOption }) {
	const setTenantSubDomain = useSetTenantSubDomain()
	const tenantDisabled = useIsTenantShutdown(tenantOption)

	const handleClick = () => {
		setTenantSubDomain(tenantOption.subDomain)
	}

	return (
		<ListGroupItem
			action
			onClick={handleClick}
			variant={tenantDisabled ? "secondary" : "primary"}
		>
			{tenantOption.internalName}{" "}
			{tenantDisabled && (
				<>
					<FontAwesomeIcon className="ml-1" icon={Icons.Ban} />
					<span>
						{" "}
						- Logins disabled after{" "}
						{getFormattedDate(tenantOption.loginsDisabledAfter)}
					</span>
				</>
			)}
		</ListGroupItem>
	)
}
