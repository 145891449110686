import Icons from "../Icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Nav, Row, Col } from "react-bootstrap"
import useIsProduction from "../../hooks/useIsProduction"
import TenantContext from "../tenancy/TenantContext"
import { useContext } from "react"
import { LinkContainer } from "react-router-bootstrap"
import { useIsTenantShutdown } from "../tenancy/TenantHooks"

export default function LeftNav(props: any) {
	const { showNav, toggleNavVisibility } = props
	const isProduction = useIsProduction()
	const isTenantShutdown = useIsTenantShutdown()
	const tenantContext = useContext(TenantContext)
	const tenantEnvironment = tenantContext.tenant?.environment
	const hasNhsNumberEnabled =
		tenantContext?.tenant?.configuration.features.nhs.englandAndWales
	const isSsoEnabled =
		tenantEnvironment?.professionalSsoSettings.isTestSsoEndpointEnabled

	return (
		<nav className="d-none d-md-block bg-light sidebar text-left">
			<Row>
				<Col
					className="sidebar-sticky"
					style={{ display: showNav ? "block" : "none" }}
				>
					<Nav
						activeKey={window.location.pathname}
						className="nav flex-column"
						as="ul"
					>
						<LinkContainer className="nav-link" to="">
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.TachometerAlt}
									fixedWidth
								/>
								Dashboard{" "}
								<span className="sr-only">(current)</span>
							</Nav.Item>
						</LinkContainer>
					</Nav>

					<h6 className="sidebar-heading px-3 mt-1 text-muted">
						Professionals
					</h6>
					<Nav
						activeKey={window.location.pathname}
						className="nav flex-column mb-2"
						as="ul"
					>
						<LinkContainer
							className="nav-link"
							to="professionals/search"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.UserMd}
									fixedWidth
								/>
								Manage Professionals
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="professionals/add"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.UserPlus}
									fixedWidth
								/>
								Add Professional
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="professionals/bulkadd/consultants"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.Upload}
									fixedWidth
								/>
								Bulk Add Consultants
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="professionals/bulkadd/secretaries"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.Upload}
									fixedWidth
								/>
								Bulk Add Secretaries
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="professionals/sharing/bulkshare"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.Share}
									fixedWidth
								/>
								Bulk Share
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="professionals/sharing/bulkadd"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.ShareAlt}
									fixedWidth
								/>
								Share All
							</Nav.Item>
						</LinkContainer>
					</Nav>
					<h6 className="sidebar-heading px-3 mt-1 text-muted">
						Patients
					</h6>
					<Nav
						activeKey={window.location.pathname}
						className="nav flex-column mb-2"
						as="ul"
					>
						<LinkContainer
							className="nav-link"
							to="patients/search"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.Users}
									fixedWidth
								/>
								Manage Patients
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="patients/issues"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.Procedures}
									fixedWidth
								/>
								Fix Assessment Issues
							</Nav.Item>
						</LinkContainer>
						{hasNhsNumberEnabled && (
							<LinkContainer
								className="nav-link"
								to="patients/nhsNumbers"
							>
								<Nav.Item as="li" className="nav-item">
									<FontAwesomeIcon
										icon={Icons.HospitalUser}
										fixedWidth
									/>
									Upload NHS Numbers
								</Nav.Item>
							</LinkContainer>
						)}
						<LinkContainer
							className="nav-link"
							to="patients/events"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.ClipboardList}
									fixedWidth
								/>
								Event Log
							</Nav.Item>
						</LinkContainer>
						{!isProduction && (
							<LinkContainer className="nav-link" to="fakedata">
								<Nav.Item as="li" className="nav-item">
									<FontAwesomeIcon
										icon={Icons.UserNinja}
										fixedWidth
									/>
									Fake Data
								</Nav.Item>
							</LinkContainer>
						)}
					</Nav>
					<h6 className="sidebar-heading px-3 mt-1 text-muted">
						Extracts
					</h6>
					<Nav
						activeKey={window.location.pathname}
						className="nav flex-column mb-2"
						as="ul"
					>
						<LinkContainer
							className="nav-link"
							to="extracts/consultant-emails"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.FileExport}
									fixedWidth
								/>
								Consultant Emails
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="extracts/nhsengland"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.FileExport}
									fixedWidth
								/>
								NHS England
							</Nav.Item>
						</LinkContainer>
						<LinkContainer className="nav-link" to="extracts/phin">
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.FileExport}
									fixedWidth
								/>
								PHIN
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="extracts/tenant"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.FileExport}
									fixedWidth
								/>
								Tenant Specific
							</Nav.Item>
						</LinkContainer>
					</Nav>
					<h6 className="sidebar-heading px-3 mt-1 text-muted">
						Integrations
					</h6>
					<Nav
						activeKey={window.location.pathname}
						className="nav flex-column mb-2"
						as="ul"
					>
						<LinkContainer
							className="nav-link"
							to="integrations/bridge-records"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.FileImport}
									fixedWidth
								/>
								Bridge Records
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="integrations/unpack-transform-merge"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.BriefcaseArrowRight}
									fixedWidth
								/>
								Unpack, Transform & Merge
							</Nav.Item>
						</LinkContainer>
					</Nav>
					<h6 className="sidebar-heading px-3 mt-1 text-muted">
						Templates
					</h6>
					<Nav
						activeKey={window.location.pathname}
						className="nav flex-column mb-2"
						as="ul"
					>
						<LinkContainer
							className="nav-link"
							to="templates/emails/preview"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.EnvelopeOpenText}
									fixedWidth
								/>
								Email Preview
							</Nav.Item>
						</LinkContainer>
						<LinkContainer
							className="nav-link"
							to="templates/sms/preview"
						>
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon icon={Icons.Sms} fixedWidth />
								SMS Preview
							</Nav.Item>
						</LinkContainer>
					</Nav>
					{isSsoEnabled && (
						<>
							<h6 className="sidebar-heading px-3 mt-1 text-muted">
								Client
							</h6>
							<Nav
								activeKey={window.location.pathname}
								className="nav flex-column mb-2"
								as="ul"
							>
								<LinkContainer
									className="nav-link"
									to="client/sso/professional"
								>
									<Nav.Item as="li" className="nav-item">
										<FontAwesomeIcon
											icon={Icons.Key}
											fixedWidth
										/>
										Professional SSO
									</Nav.Item>
								</LinkContainer>
							</Nav>
						</>
					)}
					<h6 className="sidebar-heading px-3 mt-1 text-muted">
						Super User
					</h6>
					<Nav
						activeKey={window.location.pathname}
						className="nav flex-column mb-2"
						as="ul"
					>
						{!isProduction && (
							<LinkContainer className="nav-link" to="devtools">
								<Nav.Item as="li" className="nav-item">
									<FontAwesomeIcon
										icon={Icons.UserNinja}
										fixedWidth
									/>
									Development Tools
								</Nav.Item>
							</LinkContainer>
						)}
						<LinkContainer className="nav-link" to="assessments">
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.ListAlt}
									fixedWidth
								/>
								Assessments
							</Nav.Item>
						</LinkContainer>
						<LinkContainer className="nav-link" to="states">
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.ListAlt}
									fixedWidth
								/>
								State Machines
							</Nav.Item>
						</LinkContainer>
						<LinkContainer className="nav-link" to="config-search">
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.FolderSearch}
									fixedWidth
								/>
								Config Search
							</Nav.Item>
						</LinkContainer>
						<LinkContainer className="nav-link" to="audit">
							<Nav.Item as="li" className="nav-item">
								<FontAwesomeIcon
									icon={Icons.ClipboardList}
									fixedWidth
								/>
								Audit
							</Nav.Item>
						</LinkContainer>
						{isTenantShutdown && (
							<LinkContainer
								className="nav-link"
								to="decommission"
							>
								<Nav.Item as="li" className="nav-item">
									<FontAwesomeIcon
										icon={Icons.Power}
										fixedWidth
									/>
									Decommission
								</Nav.Item>
							</LinkContainer>
						)}
					</Nav>
				</Col>
				<Col className="sidebar-hider" onClick={toggleNavVisibility}>
					<div className="row h-100">
						{showNav && (
							<FontAwesomeIcon
								className="my-auto"
								icon={Icons.ChevronLeft}
							/>
						)}
						{!showNav && (
							<FontAwesomeIcon
								className="my-auto"
								icon={Icons.ChevronRight}
							/>
						)}
					</div>
				</Col>
			</Row>
		</nav>
	)
}
