import { useSelector } from "react-redux"
import { State } from "../state/reducer"
import { DateTime } from "luxon"
import { useCallback } from "react"

export const useGetDate = () => {
	const { overriddenDate } = useSelector((state: State) => state.environment)

	const getDate = useCallback(() => {
		const today = DateTime.utc()

		return overriddenDate
			? DateTime.fromISO(`${overriddenDate}T${today.toISOTime()}`)
			: today
	}, [overriddenDate])

	return getDate
}

export default useGetDate
